<template>
  <div>
    <h4>NUEVOS CLIENTES</h4>
    <table class="table">
      <tr>
        <th>AÑO</th>
        <th>MES</th>
        <th>CLIENTES NUEVOS</th>
      </tr>
      <tr v-for="(l, index) in list" :key="index">
        <td>{{ l.year }}</td>
        <td>{{ l.month }}</td>
        <td>{{ l.clients }}</td>
      </tr>
    </table>

    <div style="width:100%;">
      <canvas id="canvas"></canvas>
    </div>
  </div>
</template>

<script>
// import http from "../../utils/http";
import Chart from "chart.js";
import "./utils";
import { DentalClinicService } from "../service";

export default {
  data: () => ({
    list: []
  }),
  mounted() {
    DentalClinicService.getClientsReport().then(data => {
      this.list = data;
      // var MONTHS = [
      //   "January",
      //   "February",
      //   "March",
      //   "April",
      //   "May",
      //   "June",
      //   "July",
      //   "August",
      //   "September",
      //   "October",
      //   "November",
      //   "December"
      // ];
      var config = {
        type: "line",
        data: {
          labels: [
            // "January",
            // "February",
            // "March",
            // "April",
            // "May",
            // "June",
            // "July"
            ...data.map(x => x.year + "_" + x.month)
          ],
          datasets: [
            {
              label: "Nuevos Pacientes",
              backgroundColor: window.chartColors.red,
              borderColor: window.chartColors.red,
              data: [
                ...data.map(x => x.clients)
                // randomScalingFactor(),
                // randomScalingFactor(),
                // randomScalingFactor(),
                // randomScalingFactor(),
                // randomScalingFactor(),
                // randomScalingFactor(),
                // randomScalingFactor()
              ],
              fill: false
            }
            // {
            //   label: "My Second dataset",
            //   fill: false,
            //   backgroundColor: window.chartColors.blue,
            //   borderColor: window.chartColors.blue,
            //   data: [
            //     randomScalingFactor(),
            //     randomScalingFactor(),
            //     randomScalingFactor(),
            //     randomScalingFactor(),
            //     randomScalingFactor(),
            //     randomScalingFactor(),
            //     randomScalingFactor()
            //   ]
            // }
          ]
        },
        options: {
          responsive: true,
          title: {
            display: true,
            text: ""
          },
          tooltips: {
            mode: "index",
            intersect: false
          },
          hover: {
            mode: "nearest",
            intersect: true
          },
          scales: {
            xAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                  labelString: "Month"
                }
              }
            ],
            yAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                  labelString: "Value"
                }
              }
            ]
          }
        }
      };

      var ctx = document.getElementById("canvas").getContext("2d");
      window.myLine = new Chart(ctx, config);
      window.myLine.update();
    });
  }
};
</script>

<style>
</style>
